<template>
    <div>
        <b-sidebar backdrop id="add_vendor_request" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="formtitle" shadow >
            <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{formtitle}}</span>
                        </div>
                        <div @click="hide" id="arvBtn" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>اغلاق</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="px-3 py-2" style="direction:rtl">
                        <v-simple-table striped hover style="width:100%;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">{{$parent.lang.description}}</th>
                                        <th class="text-center">{{$parent.lang.qtty}}</th>
                                        <th class="text-center"></th>
                                    </tr>
                                </thead> 
                                <tbody id="tablerow">
                                    <tr v-for="(item,index) in tbrows" :key="index">
                                        <td class="text-center">{{ item.item_name }}</td>
                                        <td class="text-center">{{ item.qty }}</td>
                                        <td class="text-center" style="width:150px;">
                                            <b-button type="button" variant="danger" style="width:65px" @click="removeItem(item,index)">حذف</b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <v-combobox
                                                v-model="newrow.item_name"
                                                :items="itemsworkdes"
                                                small-chips
                                                
                                            ></v-combobox>
                                        </td>
                                        <td style="width:150px;text-align:center;">
                                            <b-form-input
                                                class="inborder"
                                                v-model="newrow.qty"
                                                type="number"
                                                id="newitemqty"
                                            ></b-form-input>
                                        </td>
                                        
                                        <td style="width:150px;text-align:center;">
                                            <b-button type="button" class="btn-sm" variant="success" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addRequest()' class="ma-2" style="width:100px;">اضافة </b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            formtitle: 'اضافة ارسالية',
            tbrows: [],
            newrow: {
                itemcode: '',
                item_name: '',
                qty: '',
            },
            itemsworkdes: [],
        }
    },
    methods: {
        getItems(){
            const post = new FormData();
            post.append('type', 'getVendorProducts');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[a]',1);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.itemsworkdes = res.results.data;
            });
        },
        addNewRow(){
            this.tbrows.push(this.newrow);
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: '',
            }
        },
        removeItem(item,index){
            this.tbrows.splice(item, 1);
        },
        addRequest(){
            const post = new FormData();
            post.append('type', 'addVendorRequest');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            if(this.tbrows.length > 0){
                for(let i=0;i<this.tbrows.length;i++){
                    post.append('data[rows]['+i+'][item_name]',this.tbrows[i].item_name);
                    post.append('data[rows]['+i+'][qty]',this.tbrows[i].qty);
                }
                axios.post(
                    this.$store.state.SAMCOTEC.r_path, post
                ).then((response) => {
                    const res = response.data;
                    this.$parent.getRequests();
                    this.tbrows = [];
                    document.getElementById('arvBtn').click();
                });
            }else{
                //
            }
        }
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
       this.getItems();
    },
}
</script>